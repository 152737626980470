import React from 'react';
import { Center, Text, Button } from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';

interface VerificationResultProps {
  status: 'success' | 'error';
  level: number;
  onRetry: () => void;
  onSuccessClick: () => void;
  successTitle?: string;
  successDescription?: string;
  errorTitle?: string;
  errorDescription?: string;
}

const VerificationResult: React.FC<VerificationResultProps> = ({
  status,
  level,
  onRetry,
  onSuccessClick,
  successTitle = "Verification successful",
  successDescription = "",
  errorTitle = "Verification failed",
  errorDescription = "",
}) => {
  return (
    <Center flexDirection="column">
      {status === 'success' ? (
        <>
          <CheckIcon fontSize="120px" color="#67AE3C" mx={20} mt={20} mb={level === 3 ? 20 : 0} />
          <Text p={2} fontWeight="bold">{successTitle}</Text>
          <Text pb={6}>{successDescription}</Text>
          <Button variant="outlineDark" onClick={onSuccessClick}>
            OK
          </Button>
        </>
      ) : (
        <>
          <CloseIcon fontSize="120px" color="#B00C0C" m={20} />
          <Text p={2} fontWeight="bold">{errorTitle}</Text>
          <Text pb={6}>{errorDescription}</Text>
          <Button variant="outlineDark" onClick={onRetry}>
            Retry
          </Button>
        </>
      )}
    </Center>
  );
};

export default VerificationResult;