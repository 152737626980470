import { Link as RouterLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-black text-white px-8 py-10 xl:pr-20 lg:px-0 lg:py-12">
      <div className="flex flex-col-reverse lg:flex-row xl:justify-between items-start space-y-10 lg:space-y-0">
        {/* Logo Section */}
        <div className="w-full xl:w-1/5 lg:w-[13%] text-center lg:text-left">
          <div className="flex flex-col justify-center lg:justify-start items-center space-x-2 pt-6 lg:pt-0">
            {/* Logo */}
            <div className="flex items-center ml-3">
              <img src="./assets/img/logo.svg" alt="Logo" className="w-16 h-16" />
            </div>
            <span className="text-xl font-semibold">twin3.ai</span>
          </div>
        </div>

        {/* Links Section */}
        <div className="flex flex-wrap lg:flex-nowrap w-full lg:w-2/4 lg:space-x-10">
          {/* Social Media Links */}
          <div className="w-full md:w-1/3 text-center lg:text-left flex-row">
            <h5 className="font-medium uppercase text-sm lg:text-base">Social Media</h5>
            <div className="flex justify-center lg:justify-start 2xl:space-x-4">
              <a
                href="https://x.com/twin3_ai"
                target="_blank"
                rel="noreferrer noopener">
                <img src="./assets/img/twitter.png" alt="Twitter" />
              </a>
              <a
                href="https://opensea.io/collection/twin3"
                target="_blank"
                rel="noreferrer noopener">
                <img src="./assets/img/open sea.png" alt="OpenSea" />
              </a>
              <a
                href="https://warpcast.com/twin3.eth"
                target="_blank"
                rel="noreferrer noopener">
                <img src="./assets/img/wrapcast.png" alt="Wrapcast" />
              </a>
            </div>
          </div>

          {/* Resource Links */}
          <div className="w-full md:w-1/3 text-center lg:text-left 2xl:mb-2 pt-4 md:pt-0">
            <h5 className="font-medium uppercase mb-3 text-sm lg:text-base">Resource</h5>
            <ul className="space-y-2">
              <li ><a href="https://whitepaper.twin3.ai/"
                target="_blank"
                rel="noreferrer noopener" className="text-[#525252] hover:text-white text-sm lg:text-base">Whitepaper</a></li>
              <li><RouterLink to="/tasks/1" className="text-[#525252] hover:text-white text-sm lg:text-base">Tasks</RouterLink></li>
            </ul>
          </div>

          {/* Features Links */}
          <div className="w-full md:w-1/3 text-center lg:text-left 2xl:mb-10 pt-4 md:pt-0">
            <h5 className="font-medium uppercase mb-3 text-sm lg:text-base">Features</h5>
            <ul className="space-y-2">
              <li><RouterLink to="/dashboard" className="text-[#525252] hover:text-white text-sm lg:text-base">My Twin</RouterLink></li>
            </ul>
          </div>
        </div>

        {/* Newsletter Section */}
        <div className="w-full lg:w-1/4 text-center lg:text-left space-y-5">
          <h5 className="font-medium uppercase mb-3 text-sm lg:text-base">Subscribe to Twin3 Newsletter</h5>
          <div className="flex flex-col lg:flex-row items-center lg:space-x-2 space-y-4 lg:space-y-0">
            <a
              href="https://www.linkedin.com/company/twin3-ai"
              target="_blank"
              rel="noreferrer noopener"
              className="px-4 py-2 border border-[#373737] text-black bg-white rounded-lg hover:bg-transparent hover:text-white hover:border-white transition duration-300 text-sm lg:text-base"
            >
              Subscribe
            </a>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="mt-10 text-center text-gray-500 text-sm lg:text-base">
        © 2025 Twin3.ai v1.0.6
      </div>
    </footer>
  );
};

export default Footer;