import { useEffect } from 'react';

interface GoogleAnalyticsProps {
  measurementId: string;
}

const GoogleAnalytics = ({ measurementId }: GoogleAnalyticsProps) => {
  useEffect(() => {
    // Create the script elements and append them to the document head
    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    
    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${measurementId}');
    `;
    
    // Append scripts to the document head
    document.head.appendChild(scriptTag);
    document.head.appendChild(inlineScript);
    
    // Clean up on component unmount
    return () => {
      document.head.removeChild(scriptTag);
      document.head.removeChild(inlineScript);
    };
  }, [measurementId]);
  
  return null;
};

export default GoogleAnalytics; 