import { useEffect, useMemo, useState } from "react";
import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import useNFTData from "@/hooks/useNFTData";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { setDisplayValues } from "@/state/nftRenderer/nftRendererSlice";
import { Lock } from "lucide-react";
import { cn } from "@/libs/utils";

interface TaskGridProps {
  tasks: {
    row: string;
    cells: Array<{
      status:
        | "not_on_chain"
        | "not_on_chain_and_has_new_task"
        | "on_chain"
        | "empty_task"
        | "new_task";
      value: string;
    }>;
  }[];
}

const IndexInformation = ({
  title,
  lines,
}: {
  title: string;
  lines?: string[];
}) => (
  <Box maxW="60%">
    <Text fontSize="3xl" mb={5} fontWeight="bold">
      {title}
    </Text>
    <UnorderedList>
      {lines?.map((line: string, index) => (
        <ListItem key={index}>{line}</ListItem>
      ))}
    </UnorderedList>
  </Box>
);

const SBTRender = () => {
  const [currentIndex, setCurrentIndex] = useState<number>();
  const { displayValues: displayValuesFromHooks } = useNFTData();
  const dispatch = useAppDispatch();
  const displayValuesFromStore = useAppSelector((state) => state.nftRenderer);
  const rows = Array.from({ length: 16 }, (_, i) =>
    (i * 16).toString(16).padStart(4, "0").toUpperCase()
  );
  const tasks: TaskGridProps["tasks"] = [];
  // map rows and the displayValues to the tasks 16*16 array
  for (let i = 0; i < displayValuesFromStore.length; i += 16) {
    tasks.push({
      row: rows[i / 16],
      cells: displayValuesFromStore.slice(i, i + 16).map((value) => ({
        status: value.status,
        value: value.data,
      })),
    });
  }

  useEffect(() => {
    if (displayValuesFromHooks.length === 0) {
      return;
    }
    dispatch(setDisplayValues(displayValuesFromHooks));
  }, [displayValuesFromHooks, dispatch]);

  const indexInformation = useMemo(() => {
    if (currentIndex === 0) {
      // humanity index
      return (
        <IndexInformation
          title="#001 Humanity Index"
          lines={[
            "Address: 0x(0000)",
            "Goal: Calculate your likelihood of being human by passing various levels of human verification tests.",
          ]}
        />
      );
    } else if (currentIndex === 80) {
      // community builder index
      return (
        <IndexInformation
          title="#002 Community Builder Index"
          lines={["Address: 0x(0050)"]}
        />
      );
    } else if (currentIndex === 192) {
      // web3 knowledge Index
      return (
        <IndexInformation
          title="#003 Web3 Knowledge Level Index"
          lines={["Address: 0x(00C0)"]}
        />
      );
    }
    return <IndexInformation title="Coming Soon" />;
  }, [currentIndex]);

  return (
    <div className="relative overflow-x-auto font-mono text-xs flex justify-center w-full">
      <div className="max-w-full">
        {tasks.map((row, rowIndex) => (
          <div key={rowIndex} className="flex items-center mb-[8px] gap-[8px]">
            <span className="w-8 text-[0.6rem]">
              {row.row}
            </span>
            <div className="flex space-x-[8px]">
              {row.cells.map((cell, cellIndex) => (
                <div
                  key={cellIndex}
                  className={cn(
                    "w-6 h-6 rounded-full flex items-center justify-center border text-[0.6rem]",
                    cell.status === "not_on_chain" &&
                      "border-red-500 bg-red-500/10 text-red-500",
                    cell.status === "not_on_chain_and_has_new_task" &&
                      "border-red-500 bg-red-500/10 text-red-500", 
                    cell.status === "on_chain" &&
                      "border-white bg-white/10 text-white",
                    cell.status === "empty_task" &&
                      "border-gray-500 bg-gray-500/10 text-gray-500",
                    cell.status === "new_task" &&
                      "border-[#34C759] bg-[#34C759]/10 text-[#34C759]"
                  )}
                >
                  {cell.status === "empty_task" ? "00" : cell.value}
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className="absolute inset-0 flex items-center justify-center pointer-events-none opacity-10">
          <Lock className="w-16 h-16 text-muted-foreground" />
        </div>
      </div>
    </div>
  );
};

export default SBTRender;
