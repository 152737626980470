import * as React from "react";
import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Diamond, ListTodo, User2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { TASK_MENU_ITEMS } from "./constants";

const BottomNavigation: React.FC = () => {
  const [isBottomTaskMenuOpen, setIsBottomTaskMenuOpen] = useState(false);
  const bottomTaskMenuRef = useRef<HTMLDivElement>(null);

  // Close bottom task menu when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        bottomTaskMenuRef.current &&
        !bottomTaskMenuRef.current.contains(event.target as Node)
      ) {
        setIsBottomTaskMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="md:hidden fixed bottom-0 left-0 right-0 bg-background border-t border-border flex justify-around items-center h-20 z-10">
      <Link to="/dashboard" className="flex flex-col items-center">
        <Button
          variant="ghost"
          size="icon"
          className="text-muted-foreground hover:text-primary"
        >
          <User2 className="h-5 w-5" />
        </Button>
        <span className="text-xs mt-1 text-muted-foreground">My Twin</span>
      </Link>

      <Link to="/genesisNFT" className="flex flex-col items-center">
        <Button
          variant="ghost"
          size="icon"
          className="text-muted-foreground hover:text-primary"
        >
          <Diamond className="h-5 w-5" />
        </Button>
        <span className="text-xs mt-1 text-muted-foreground">Genesis NFT</span>
      </Link>

      <div className="relative flex flex-col items-center" ref={bottomTaskMenuRef}>
        <Button
          variant="ghost"
          size="icon"
          className="text-muted-foreground hover:text-primary"
          onClick={() => setIsBottomTaskMenuOpen(!isBottomTaskMenuOpen)}
        >
          <ListTodo className="h-5 w-5" />
        </Button>
        <span className="text-xs mt-1 text-muted-foreground">Task</span>

        {isBottomTaskMenuOpen && (
          <div className="absolute bottom-20 left-1/2 transform -translate-x-1/2 bg-background border border-border rounded p-2 w-36 shadow-md">
            <div className="flex flex-col space-y-1">
              <div className="text-center">The Task</div>
              {TASK_MENU_ITEMS.map((item) => (
                <Link
                  key={item.id}
                  to={item.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm hover:text-primary px-2 py-1 text-center"
                  onClick={() => setIsBottomTaskMenuOpen(false)}
                >
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BottomNavigation; 