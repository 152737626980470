import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Info,
  Menu,
  X,
} from "lucide-react";
import { Sidebar, BottomNavigation } from "@/components/navigation";
import { UpgradeForm } from "@/components/upgrade-form";
import "@/global.css";
import useNFTData from "@/hooks/useNFTData";
import SBTRender from "@/components/SBTRender";
import RankingPanel from "@/components/RankingPanel";
import marketplaceConfig from "@/pages/maketplaceConfig.json";
import { useAppSelector } from "@/state/hooks";
import useSupplyData from "@/hooks/useSupplyData";
import useExchangeGenesis from "@/libs/useExchangeGenesis";
import { NFTTicketDialog } from "@/components/NFTTicketDialog";

export default function Dashboard() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const { displayValues } = useNFTData();
  const [selectedTicket, setSelectedTicket] = useState<{
    name: string;
    src: string;
    id: number;
    points: number;
  } | null>(null);
  const [canSubmit, setCanSubmit] = useState(true);

  // Initialize useExchangeGenesis with default values that will be updated when a ticket is selected
  const { submit, isConfirmed, status, hash, reset } = useExchangeGenesis(
    1,
    selectedTicket?.id || 1
  );

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const nftTickets = marketplaceConfig.map((ticket) => ({
    name: ticket.name,
    src: require(`@/assets/images/gif/${ticket.src}`),
    id: ticket.id,
    points: ticket.points,
  }));

  const { refetchTotalSupply } = useSupplyData();

  // Effect to handle successful confirmation
  useEffect(() => {
    if (isConfirmed && selectedTicket) {
      refetchTotalSupply();
      setCanSubmit(true);
    }
  }, [isConfirmed, refetchTotalSupply, selectedTicket]);

  // Refresh supply data when a ticket is selected
  useEffect(() => {
    if (selectedTicket) {
      refetchTotalSupply();
    }
  }, [selectedTicket, refetchTotalSupply]);

  const handleTicketClick = (ticket: typeof selectedTicket) => {
    setSelectedTicket(ticket);
    reset(); // Reset the status when selecting a new ticket
  };

  const handleDialogChange = (open: boolean) => {
    reset();
    setSelectedTicket(null);
  };

  return (
    <div className="flex min-h-screen dark">
      {/* Mobile menu button */}
      <button
        className="md:hidden fixed top-4 left-4 z-20 bg-background rounded-full p-2 shadow-md"
        onClick={toggleMobileMenu}
      >
        {isMobileMenuOpen ? (
          <X className="h-5 w-5" />
        ) : (
          <Menu className="h-5 w-5" />
        )}
      </button>

      {/* Sidebar Component */}
      <Sidebar />

      {/* Bottom Navigation Component */}
      <BottomNavigation />

      {/* Main Content */}
      <main className="flex-1 md:ml-16 bg-background p-3 md:p-5 w-full overflow-x-hidden">
        {/* intro part */}
        <div className="flex flex-col items-start max-w-[1200px] mx-auto mt-10 md:mt-0">
          <div className="flex flex-col items-start w-full px-2">
            <h1 className="text-3xl md:text-5xl">ME</h1>
            <p className="text-lg md:text-2xl mt-2">
              The true you in different dimensions, staying transparent yet
              mysterious.
            </p>
            <p className="text-xs md:text-sm text-muted-foreground p-2 md:p-5">
              Attribute values and rankings are hidden in the black number for
              the airdrop index. Update more attributes to get more
              airdrops.....MORE
            </p>
          </div>
        </div>

        <div className="grid gap-4 md:gap-6 max-w-[1200px] mx-auto px-2">
          <div className="space-y-4 md:space-y-6">
            {/* Stats */}
            <RankingPanel displayValues={displayValues} />

            {/* Dashboard Grid*/}
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-[8fr_2fr_6fr]">
              {/* DNA Display */}
              <Card className="border-2 border-primary/20 w-full">
                <CardHeader>
                  <CardTitle className="flex items-center gap-2 text-lg font-medium">
                    Twin Matrix
                    <Button variant="ghost" size="icon" className="h-4 w-4">
                      <Info className="h-3 w-3" />
                    </Button>
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <SBTRender />
                </CardContent>
              </Card>

              {/* Genesis NFT Bag */}
              <div className="flex items-center justify-center w-full">
                <div className="grid grid-cols-2 md:grid-cols-1 gap-2 w-full">
                  {nftTickets.map((ticket, index) => (
                    <div
                      key={index}
                      className="ticket flex flex-col items-center justify-center cursor-pointer hover:bg-[#2a2a2a] transition-colors p-1"
                      onClick={() => handleTicketClick(ticket)}
                    >
                      <img
                        src={ticket.src}
                        alt={ticket.name}
                        className="max-h-14 object-contain"
                      />
                      <p className="text-xs mt-2">{ticket.name}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* Upgrade Form */}
              <Card className="bg-[#1C1C1C] w-full border-0 border-none shadow-none">
                <CardHeader>
                  <CardTitle className="text-lg font-medium">
                    Twin Matrix Update
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <UpgradeForm />
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </main>

      {/* NFT Ticket Dialog */}
      <NFTTicketDialog
        selectedTicket={selectedTicket}
        onOpenChange={handleDialogChange}
        onSubmit={submit}
        canSubmit={canSubmit}
        setCanSubmit={setCanSubmit}
        status={status}
        hash={hash}
      />
    </div>
  );
}
