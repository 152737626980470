import { Twin3Genesis_CONTRACT_ADDRESS } from "@/utils/constants";
import Twin3Genesis_ABI from "@/abi/Twin3Genesis _abi.json";
import { useReadContracts } from "wagmi";
import { useEffect, useMemo } from 'react';
import { useAppDispatch } from '@/state/hooks';
import { setRemainingSupply } from "@/state/genesisNFT/genesisNFTSlice";

/**
 * Hook to fetch and calculate NFT supply data from the contract
 * @returns Object containing refetch function for totalSupply
 */
export const useSupplyData = () => {
  const dispatch = useAppDispatch();

  const SupplyContract = {
    address: Twin3Genesis_CONTRACT_ADDRESS,
    abi: Twin3Genesis_ABI,
  } as const;

  const { data: totalSupply, refetch: refetchTotalSupply } = useReadContracts({
    contracts: (() => {
      const contracts = [];
      for (let i = 0; i < 6; i++) {
        contracts.push({
          ...SupplyContract,
          functionName: "totalSupply",
          args: [i],
        });
      }
      return contracts;
    })() as Array<any>,
  });

  const { data: maxSupply } = useReadContracts({
    contracts: (() => {
      const contracts = [];
      for (let i = 0; i < 6; i++) {
        contracts.push({
          ...SupplyContract,
          functionName: "maxSupply",
          args: [i],
        });
      }
      return contracts;
    })() as Array<any>,
  });

  const nft_maxSupply = useMemo(() =>
    maxSupply?.map((tier) =>
      parseInt((tier.result as number).toString().replace("n", ""))
    ) || []
    , [maxSupply]);

  const nft_totalSupply = useMemo(() =>
    totalSupply?.map((tier) =>
      parseInt((tier.result as number).toString().replace("n", ""))
    ) || []
    , [totalSupply]);

  useEffect(() => {
    if (nft_totalSupply.length > 0 && nft_maxSupply.length > 0) {
      const leftNFT: number[] = [];
      nft_totalSupply.forEach((supply, index) => {
        leftNFT.push(nft_maxSupply[index] - supply);
      });
      dispatch(setRemainingSupply(leftNFT));
    }
  }, [nft_totalSupply, nft_maxSupply, dispatch]);

  return {
    refetchTotalSupply,
  };
};

export default useSupplyData; 