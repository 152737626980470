"use client";

import * as React from "react";
import { Button } from "@/components/ui/button";
import { CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { InfoIcon } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useContext, useState, useCallback, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import AccountContext from "@/context/account";
import useNFTData from "@/hooks/useNFTData";
import useUpdateSBT from "@/libs/useUpdateSBT";
import { useReadContract } from "wagmi";
import { getSignature } from "@/api";
import {
  Twin3Genesis_CONTRACT_ADDRESS,
  USDT_CONTRACT_ADDRESS,
  Twin3ScoreExchange_CONTRACT_ADDRESS,
} from "@/utils/constants";
import GENESIS_ABI from "@/abi/Twin3Genesis _abi.json";
import USDT_ABI from "@/abi/USDT_abi.json";
import SCORE_EXCHANGE_ABI from "@/abi/Twin3ScoreExchange_abi.json";
import { convertToUSDTUnits } from "@/utils/web3Helper";

// Custom hook to get the latest ETH price
const useETHPrice = () => {
  const { data, isError, isLoading, refetch } = useReadContract({
    address: Twin3ScoreExchange_CONTRACT_ADDRESS,
    abi: SCORE_EXCHANGE_ABI,
    functionName: "getLatestETHPrice",
  });

  // Convert the returned price (which is likely in a different format/scale) to USD
  const ethPriceInUSD = data ? Number(data) / 100000000 : null; // Return null when data isn't available

  useEffect(() => {
    // Initial fetch
    refetch();
    
    // Set up interval to fetch price every 60 seconds (common update frequency for exchange rates)
    const intervalId = setInterval(() => {
      refetch();
    }, 60000); // 60 seconds
    
    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [refetch]);

  return {
    ethPriceInUSD,
    isError,
    isLoading,
    refetch,
  };
};

interface CurrencyOption {
  value: string;
  label: string;
  description?: string;
}

// Define the type for exchange rates
interface ExchangeRates {
  USDT: number;
  ETH: number | null;
}

const currencies: CurrencyOption[] = [
  { value: "USDT", label: "USDT" },
  { value: "ETH", label: "ETH" },
];

export function UpgradeForm() {
  const { userData } = useNFTData();
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState<string>("USDT");
  const { account, id } = useContext(AccountContext);
  // Get the latest ETH price
  const { ethPriceInUSD, isLoading: ethPriceLoading, refetch: refetchETHPrice } = useETHPrice();
  
  // Update useUpdateSBT to pass currency and ethPriceInUSD
  const { submit } = useUpdateSBT(userData, Number(amount), currency, ethPriceInUSD);
  const [canSubmit, setCanSubmit] = useState(true);
  const [showCountdown, setShowCountdown] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const toast = useToast();
  
  // Dynamic exchange rates based on the latest ETH price
  const [exchangeRates, setExchangeRates] = useState<ExchangeRates>({
    USDT: 1,
    ETH: null, // Start with null instead of a default value
  });
  
  // Update exchange rates when ETH price changes
  useEffect(() => {
    setExchangeRates(prevRates => ({
      ...prevRates,
      ETH: ethPriceInUSD,
    }));
  }, [ethPriceInUSD]);

  // Add manual refresh of ETH price when currency is changed to ETH
  useEffect(() => {
    if (currency === "ETH") {
      refetchETHPrice();
    }
  }, [currency, refetchETHPrice]);

  const { refetch: refetchTotalSupply } = useReadContract({
    address: Twin3Genesis_CONTRACT_ADDRESS,
    abi: GENESIS_ABI,
    functionName: "totalSupply",
    args: [id],
  });

  const { refetch: refetchMaxSupply } = useReadContract({
    address: Twin3Genesis_CONTRACT_ADDRESS,
    abi: GENESIS_ABI,
    functionName: "maxSupply",
    args: [id],
  });

  const { data: balance, refetch: refetchBalance } = useReadContract({
    address: USDT_CONTRACT_ADDRESS,
    abi: USDT_ABI,
    functionName: "balanceOf",
    args: [account],
  });

  const handleSubmit = useCallback(async () => {
    await refetchBalance();
    await refetchTotalSupply();
    await refetchMaxSupply();

    if (Number(amount) === 0) {
      toast({
        title: `Please input ${currency === "USDT" ? "USDT" : "USD"} amount!`,
        status: "error",
      });
      return;
    }

    if (currency === "USDT") {
      if (parseInt(balance as string) < convertToUSDTUnits(Number(amount))) {
        toast({
          title: "Not enough USDT to approve!",
          status: "error",
        });
        return;
      }
    } else if (currency === "ETH") {
      // For ETH, we'll check balance at the contract level
      if (!ethPriceInUSD) {
        toast({
          title: "ETH price unavailable. Try again.",
          status: "error",
        });
        return;
      }
    }

    if (!canSubmit) {
      return;
    }
    
    setCanSubmit(false);
    setShowCountdown(true);
    const response = await getSignature();
    await submit(response.signature);

    const interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(interval);
          setCanSubmit(true);
          setShowCountdown(false);
          return 10;
        }
        return prev - 1;
      });
    }, 1000);
  }, [
    canSubmit,
    submit,
    amount,
    currency,
    ethPriceInUSD,
    balance,
    refetchBalance,
    refetchTotalSupply,
    refetchMaxSupply,
    toast,
  ]);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const usdAmount = e.target.value;
    setAmount(usdAmount);
  };

  const handleCurrencyChange = (value: string) => {
    setCurrency(value);
  };

  const getUSDValue = () => {
    if (!amount || isNaN(Number(amount))) return "0.00";
    return Number(amount).toFixed(2);
  };

  const getCurrencyValue = () => {
    if (!amount || isNaN(Number(amount))) return "0.000000";
    
    // Handle the case when the rate isn't loaded yet
    const rate = exchangeRates[currency as keyof typeof exchangeRates];
        
    if (rate === null) {
      return "Loading...";
    }
    
    return (Number(amount) / rate).toFixed(6);
  };

  const getExchangeRate = () => {
    if (currency === "USDT") return null;
    
    const rate = exchangeRates[currency as keyof typeof exchangeRates];
        
    if (rate === null) {
      return `${currency} price: Loading...`;
    }
    
    return `1 ${currency} = $${rate.toFixed(2)} USD`;
  };

  const getExchangeAmount = () => {
    if (!amount || isNaN(Number(amount))) return "0.000000";
    
    const rate = exchangeRates[currency as keyof typeof exchangeRates];
        
    if (rate === null) {
      return "Loading...";
    }
    
    return (Number(amount) / rate).toFixed(6);
  };

  return (
    <CardContent className="p-0">
      <div className="flex flex-col justify-between space-y-8">
        <div className="flex-1 space-y-6">
          <div>
            <div className="mb-4 flex items-center">
              <span className="mr-2 rounded-full bg-black px-2 py-1 text-lg text-white">
                01
              </span>
              <span className="text-lg font-medium">
                SELECT a Token for Transaction Fee Payment
              </span>
            </div>
            <div className="space-y-4">
              <div className="space-y-3">
                <Select value={currency} onValueChange={handleCurrencyChange}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select currency" />
                  </SelectTrigger>
                  <SelectContent>
                    {currencies.map((curr) => (
                      <SelectItem key={curr.value} value={curr.value}>
                        <div className="flex items-center justify-between w-full">
                          <span>{curr.label}</span>
                          {curr.value === "ETH" && ethPriceLoading && (
                            <span className="text-xs text-muted-foreground ml-2">
                              Loading price...
                            </span>
                          )}
                          {curr.description && (
                            <span className="text-xs text-muted-foreground ml-2">
                              {curr.description}
                            </span>
                          )}
                        </div>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {currency !== "USDT" && (
                  <div className="text-sm text-muted-foreground">
                    {getExchangeRate()}
                    {currency === "ETH" && ethPriceLoading && (
                      <div className="text-xs text-muted-foreground mt-1">
                        Fetching latest price from blockchain...
                      </div>
                    )}
                  </div>
                )}
                <div className="relative">
                  <Input
                    type="number"
                    placeholder="Enter USD amount"
                    value={amount}
                    onChange={handleAmountChange}
                    className="pr-12"
                  />
                  <div className="absolute right-3 top-1/2 -translate-y-1/2 text-sm text-muted-foreground">
                    USD
                  </div>
                </div>
                {amount && (
                  <div className="text-sm text-muted-foreground">
                    ≈ {getExchangeAmount()} {currency}
                  </div>
                )}
              </div>
              <div className="space-y-3">
                <div className="flex items-start gap-2 text-xs text-muted-foreground">
                  <InfoIcon className="h-4 w-4 shrink-0" />
                  <p>
                    For every 1 USD equivalent you spend on updating your Twin
                    Matrix, earn 1 Point.
                  </p>
                </div>
                <div className="flex items-start gap-2 text-xs text-muted-foreground">
                  <InfoIcon className="h-4 w-4 shrink-0" />
                  <p>
                    Accumulate points to redeem limited-edition Genesis NFTs, exclusively for early supporters.
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div>
            <div className="mb-4 flex items-center">
              <span className="mr-2 rounded-full bg-black px-2 py-1 text-lg text-white">
                02
              </span>
              <span className="text-lg font-medium">CONFIRM</span>
            </div>

            <div className="space-y-4">
              <p className="text-xs text-muted-foreground">
                This feature will be available soon. Stay tuned for updates!
              </p>
              <Button
                className="w-full bg-black text-white opacity-50 cursor-not-allowed"
                disabled={true}
              >
                Coming Soon
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CardContent>
  );
}
