import { formatUnits } from 'viem';

// 添加驗證函數
export function isValidNumber(value: string): boolean {
  // 只允許正整數
  const numberRegex = /^[0-9]\d*$/;
  return numberRegex.test(value);
}

export function convertToUSDTUnits(amount: number): number {
  return amount * Math.pow(10, 6);
}

export function convertToUSDTUnitsString(amount: number): number {
  return Number(formatUnits(BigInt(amount), 6));
}

// Convert USD amount to ETH (wei) based on ETH price in USD
export function convertUSDToETH(usdAmount: number, ethPriceInUSD: number): bigint {
  if (!ethPriceInUSD || ethPriceInUSD === 0) return BigInt(0);
  
  // Calculate ETH amount: USD amount / ETH price
  const ethAmount = usdAmount / ethPriceInUSD;
  
  // Convert to wei (ETH has 18 decimals)
  return BigInt(Math.floor(ethAmount * Math.pow(10, 18)));
}
