import * as React from "react";
import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Diamond, ListTodo, User2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { TASK_MENU_ITEMS } from "./constants";

const Sidebar: React.FC = () => {
  const [isTaskMenuOpen, setIsTaskMenuOpen] = useState(false);
  const taskMenuRef = useRef<HTMLDivElement>(null);

  // Close task menu when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        taskMenuRef.current &&
        !taskMenuRef.current.contains(event.target as Node)
      ) {
        setIsTaskMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <aside className="hidden md:flex w-16 border-r border-border bg-background flex-col fixed h-screen z-10">
      <TooltipProvider>
        <nav className="flex-1 flex flex-col items-center gap-4 p-4">
          <Link to="/dashboard">
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className="text-muted-foreground hover:text-primary"
                >
                  <User2 className="h-5 w-5" />
                  <span className="sr-only">My Twin</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent side="right">My Twin</TooltipContent>
            </Tooltip>
          </Link>

          <Tooltip>
            <TooltipTrigger asChild>
              <Link to="/genesisNFT">
                <Button
                  variant="ghost"
                  size="icon"
                  className="text-muted-foreground hover:text-primary"
                >
                  <Diamond className="h-5 w-5" />
                  <span className="sr-only">Genesis NFT</span>
                </Button>
              </Link>
            </TooltipTrigger>
            <TooltipContent side="right">Genesis NFT</TooltipContent>
          </Tooltip>

          <div className="relative" ref={taskMenuRef}>
            <Button
              variant="ghost"
              size="icon"
              className="text-muted-foreground hover:text-primary"
              onClick={() => setIsTaskMenuOpen(!isTaskMenuOpen)}
            >
              <ListTodo className="h-5 w-5" />
              <span className="sr-only">The Task</span>
            </Button>

            {isTaskMenuOpen && (
              <div className="absolute left-full ml-2 top-0 bg-background border border-border rounded p-2 w-36 shadow-md">
                <div className="flex flex-col space-y-1">
                  <div>The Task</div>
                  {TASK_MENU_ITEMS.map((item) => (
                    <Link
                      key={item.id}
                      to={item.path}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm hover:text-primary px-2"
                    >
                      {item.label}
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
        </nav>
      </TooltipProvider>
    </aside>
  );
};

export default Sidebar; 