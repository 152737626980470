import { Card, CardContent } from "@/components/ui/card"
import { display_type } from '@/type'
import { setRankingData, setPoint, setInvitedTasks, calculateCompletionRate } from '@/state/rankingPanel/RankingPanelSlice'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import useReadScore from '@/libs/useReadScore'
import AccountContext from "@/context/account"
import useNFTData from '@/hooks/useNFTData'
import { convertToUSDTUnitsString } from '@/utils/web3Helper'
import { useEffect, useMemo, useContext } from 'react'

interface RankingPanelProps {
  displayValues?: display_type[]
}

export default function RankingPanel({ displayValues = [] }: RankingPanelProps) {
  const dispatch = useAppDispatch()
  const rankingData = useAppSelector((state) => state.rankingPanel)
  const { account, accountInfo } = useContext(AccountContext)
  const { data: rankingPoint = 0 } = useReadScore(account || '')
  const { taskData } = useNFTData()

  const newRankingData = useMemo(() => {
    const initialRankingData = {
      twinMatrix: 0,
      AlltwinMatrix: 256,
      points: 0,
      invitedTasks: 0,
      completedTasks: 0,
      completionRate: 0,
    }
    const data = { ...initialRankingData }

    displayValues.forEach((value) => {
      if (value.data !== '00') {
        data.completedTasks += 1
        data.twinMatrix += 1
      }
    })

    return data
  }, [displayValues])

  useEffect(() => {
    dispatch(setRankingData(newRankingData))
    if (accountInfo) {
      dispatch(setPoint(convertToUSDTUnitsString(rankingPoint as number)))
    }
    if (accountInfo && taskData.length > 0) {
      dispatch(setInvitedTasks(taskData.length))
    }
    dispatch(calculateCompletionRate())
  }, [newRankingData, rankingPoint, dispatch, accountInfo, taskData])

  return (
    <div className="grid grid-cols-1 gap-2 sm:grid-cols-1 lg:grid-cols-5">
      <Card className="bg-[#1C1C1C] w-full border-0 border-none shadow-none">
        <CardContent className="p-4 flex flex-col items-center text-center">
          <div className="text-2xl font-bold text-foreground">
            {rankingData.twinMatrix ?? 0}/{rankingData.AlltwinMatrix ?? 256}
          </div>
          <div className="text-xs text-muted-foreground">TWIN MATRIX</div>
        </CardContent>
      </Card>
      <Card className="bg-[#1C1C1C] w-full border-0 border-none shadow-none">
        <CardContent className="p-4 flex flex-col items-center text-center">
          <div className="text-2xl font-bold text-foreground">{rankingData.points ?? 0}</div>
          <div className="text-xs text-muted-foreground">POINTS</div>
        </CardContent>
      </Card>
      <Card className="bg-[#1C1C1C] w-full border-0 border-none shadow-none">
        <CardContent className="p-4 flex flex-col items-center text-center">
          <div className="text-2xl font-bold text-foreground">{rankingData.invitedTasks ?? 0}</div>
          <div className="text-xs text-muted-foreground">INVITED TASKS</div>
        </CardContent>
      </Card>
      <Card className="bg-[#1C1C1C] w-full border-0 border-none shadow-none">
        <CardContent className="p-4 flex flex-col items-center text-center">
          <div className="text-2xl font-bold text-foreground">{rankingData.completedTasks ?? 0}</div>
          <div className="text-xs text-muted-foreground">COMPLETED TASKS</div>
        </CardContent>
      </Card>
      <Card className="bg-[#1C1C1C] w-full border-0 border-none shadow-none">
        <CardContent className="p-4 flex flex-col items-center text-center">
          <div className="text-2xl font-bold text-orange-400">{rankingData.completionRate}%</div>
          <div className="text-xs text-muted-foreground">COMPLETION RATE</div>
        </CardContent>
      </Card>
    </div>
  )
} 