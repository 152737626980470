// Define task menu items type
export type TaskMenuItem = {
  id: string;
  label: string;
  path: string;
};

// Define main navigation items
export const MAIN_NAV_ITEMS = [
  { id: "twin", label: "My Twin", path: "/dashboard", icon: "User2" },
  { id: "genesis", label: "Genesis NFT", path: "/genesisNFT", icon: "Diamond" },
];

// Define task menu items
export const TASK_MENU_ITEMS: TaskMenuItem[] = [
  { id: "1", label: "Task1", path: "/tasks/1" },
  { id: "2", label: "Task2", path: "/tasks/2" },
  { id: "3", label: "Task3", path: "/tasks/3" },
]; 