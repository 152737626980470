import * as React from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { useToast } from "@chakra-ui/react";
import { useAppSelector } from "@/state/hooks";
import { getSignature } from "@/api";
import { Loader2 } from "lucide-react";

interface NFTTicket {
  name: string;
  src: string;
  id: number;
  points: number;
}

interface NFTTicketDialogProps {
  selectedTicket: NFTTicket | null;
  onOpenChange: (open: boolean) => void;
  onSubmit: (signature: `0x${string}`) => Promise<void>;
  canSubmit: boolean;
  setCanSubmit: (value: boolean) => void;
  status: 'idle' | 'pending' | 'waiting' | 'success' | 'error';
  hash?: `0x${string}`;
}

export function NFTTicketDialog({
  selectedTicket,
  onOpenChange,
  onSubmit,
  canSubmit,
  setCanSubmit,
  status,
  hash,
}: NFTTicketDialogProps) {
  const genesisNFTData = useAppSelector((state) => state.genesisNFT);
  const rankingData = useAppSelector((state) => state.rankingPanel);
  const toast = useToast();

  const handleRedeemClick = async () => {
    if (!selectedTicket) return;

    if (rankingData.points < selectedTicket.points) {
      toast({
        title: "You do not have enough points to redeem this NFT",
        status: "error",
      });
      return;
    }

    if (!canSubmit) {
      return;
    }

    setCanSubmit(false);
    try {
      const response = await getSignature();
      await onSubmit(response.signature);
    } catch (error) {
      console.error("Error during redemption:", error);
      toast({
        title: "Redemption failed",
        status: "error",
      });
      setCanSubmit(true);
    }
  };

  const renderNFTContent = () => {
    if (!selectedTicket) return null;
    
    return (
      <div className="text-center mb-4 w-full">
        <p className="font-bold text-lg">Tier {selectedTicket.id}</p>
        <p className="font-normal text-base">{selectedTicket.name}</p>
        <p className="text-xs mt-1">
          {genesisNFTData.remainingSupply[selectedTicket.id - 1]} Left
        </p>
        <p className="text-xs">
          {selectedTicket.points} Points / 1pc.
        </p>
        <img
          src={selectedTicket.src}
          alt={selectedTicket.name}
          className="max-h-52 h-auto object-contain my-4 mx-auto"
        />
      </div>
    );
  };

  const renderTransactionStatus = () => {
    switch (status) {
      case 'pending':
        return (
          <div className="flex flex-col items-center justify-center space-y-2 my-4">
            {renderNFTContent()}
            <div className="flex flex-col items-center">
              <Loader2 className="h-8 w-8 animate-spin text-primary" />
              <p className="text-sm text-center mt-2">Transaction Submitting...</p>
              <p className="text-xs text-center text-gray-500">Please wait while the transaction is being submitted to the blockchain</p>
            </div>
          </div>
        );
      case 'waiting':
        return (
          <div className="flex flex-col items-center justify-center space-y-2 my-4">
            {renderNFTContent()}
            <div className="flex flex-col items-center">
              <Loader2 className="h-8 w-8 animate-spin text-primary" />
              <p className="text-sm text-center mt-2">Waiting for Confirmation...</p>
              <p className="text-xs text-center text-gray-500">
                Transaction Hash: {hash ? `${hash.slice(0, 10)}...` : ''}
              </p>
            </div>
          </div>
        );
      case 'success':
        return (
          <div className="flex flex-col items-center justify-center space-y-2 my-4">
            {renderNFTContent()}
            <div className="flex flex-col items-center text-green-500">
              <svg className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
              <p className="text-sm text-center mt-2">Transaction Successful!</p>
              <p className="text-xs text-center">Your NFT has been successfully redeemed</p>
            </div>
          </div>
        );
      case 'error':
        return (
          <div className="flex flex-col items-center justify-center space-y-2 my-4 text-red-500">
            <svg className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
            <p className="text-sm text-center">Transaction Failed</p>
            <p className="text-xs text-center">Please check your wallet and try again</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={!!selectedTicket} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md border-2 border-primary/20 bg-[#0A0A0A] w-[95%] max-w-md mx-auto">
        <DialogHeader>
          <DialogTitle className="text-center font-bold text-xl md:text-2xl">
            {status === 'idle' ? 'Redeem your NFT' : 'Transaction Status'}
          </DialogTitle>
          <DialogDescription className="text-center text-sm text-gray-500">
            {status === 'idle' 
              ? 'Select an NFT to redeem with your points'
              : 'Please wait while your transaction is being processed'}
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col items-center justify-center">
          {status === 'idle' && selectedTicket && (
            <>
              {renderNFTContent()}
              <p className="font-semibold text-base text-center mt-2 mb-4">
                Redeem your NFT by clicking the button below
              </p>
            </>
          )}
          {status !== 'idle' && renderTransactionStatus()}
        </div>
        <DialogFooter className="sm:justify-center">
          {status === 'idle' && (
            <Button
              onClick={handleRedeemClick}
              className="w-full sm:w-auto bg-white text-black"
              disabled={!canSubmit || status !== 'idle'}
            >
              Redeem
            </Button>
          )}
          {status === 'success' && (
            <Button
              onClick={() => onOpenChange(false)}
              className="w-full sm:w-auto bg-green-500 text-white hover:bg-green-600"
            >
              Done
            </Button>
          )}
          {status === 'error' && (
            <Button
              onClick={() => {
                setCanSubmit(true);
                onOpenChange(false);
              }}
              className="w-full sm:w-auto bg-red-500 text-white hover:bg-red-600"
            >
              Close
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
} 