import { useCallback, useEffect, useState } from 'react';
import { useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import SCORE_ABI from '@/abi/Twin3ScoreExchange_abi.json';
import { Twin3ScoreExchange_CONTRACT_ADDRESS } from '@/utils/constants';
import { useToast } from '@chakra-ui/react';

const useExchangeGenesis = (amount: number | 1, id: number | 0) => {
  const { data: hash, writeContract, isPending, isError } = useWriteContract();
  const { 
    isSuccess: isConfirmed,
    isLoading: isWaiting,
    error: waitError
  } = useWaitForTransactionReceipt({
    hash,
  });
  const toast = useToast();
  const [status, setStatus] = useState<'idle' | 'pending' | 'waiting' | 'success' | 'error'>('idle');

  const reset = useCallback(() => {
    setStatus('idle');
  }, []);

  useEffect(() => {
    if (isPending) {
      setStatus('pending');
    }
  }, [isPending]);

  useEffect(() => {
    if (isWaiting && hash) {
      setStatus('waiting');
    }
  }, [isWaiting, hash]);

  useEffect(() => {
    if (hash && isConfirmed) {
      setStatus('success');
    }
  }, [hash, isConfirmed]);

  useEffect(() => {
    if (isError || waitError) {
      setStatus('error');
      toast({
        title: 'Contract Call Failed',
        description: waitError ? waitError.message : 'Please check your wallet settings and try again',
        status: 'error',
        duration: 5000,
      });
    }
  }, [isError, waitError, toast]);

  const submit = useCallback(
    async (signature: `0x${string}`) => {
      try {
        setStatus('pending');
        await writeContract({
          abi: SCORE_ABI,
          address: Twin3ScoreExchange_CONTRACT_ADDRESS,
          functionName: 'exchangeGenesis',
          args: [amount, id - 1],
        });
      } catch (error) {
        console.error('Error executing transactions:', error);
        setStatus('error');
        toast({
          title: 'Contract Call Failed',
          description: 'Please check your wallet settings and try again',
          status: 'error',
        });
      }
    },
    [writeContract, amount, id, toast]
  );

  return { 
    submit, 
    isConfirmed,
    status,
    hash,
    reset
  };
};

export default useExchangeGenesis;
